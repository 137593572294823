import React, { useEffect, useMemo, useRef, useState } from "react";
import { Button } from "primereact/button";
import { useHistory } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { toast } from "react-toastify";
import Axios from "axios";
import { DEV } from "../../services/constants";
import AddPackageDialog from "./AddPackageDialog";
import { Dialog } from "primereact/dialog";
import ChangeStatusDialog from "./changeStatusDialog";
import BulkFlight from "./bulkFlightDialog";
import { JsonToExcel } from "react-json-to-excel";
import PackageDetail from "./PackageDetail";
import UploadImageDialog from "./UploadImageDialog";
import SofStop from "../../modals/SofStop";
import { Tooltip } from "react-tippy";
import { handlePutRequest } from "../../services/PutTemplate";
import Outstanding from "../../modals/Outstanding";

function Packages() {
    const [selectedRow, setselectedRow] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [showChangeDialog, setShowChangeDialog] = useState(false);
    const [FlightDialog, setFlightDialog] = useState(false);
    const [brands, setBrands] = useState([]);
    const [skip, setSkip] = useState(0);
    const [role, setrole] = useState("");
    const [userId, setuserId] = useState("");
    const dispatch = useDispatch();
    const [shipment, setShipment] = useState([]);
    const [shipmentNumber, setShipmentNumber] = useState("");
    const [forXL, setForXL] = useState([]);
    const [pack, setPackage] = useState({});
    const [searchedBrands, setSearchedBrands] = useState([]);
    const [loading, setLoading] = useState(true);

    const menu = useRef(null);
    const breadItems = [{ label: "Home", url: "/" }, { label: "Packages" }];

    const home = { icon: "pi pi-home", url: "/" };
    const history = useHistory();

    useEffect(() => {
        if (selectedRow.length > 0) {
            const temp = selectedRow.map((val) => {
                const data = {
                    id: val?.Waybill,
                    name: val?.name?.firstName + " " + val?.name?.lastName,
                    deliveryAddress: val?.name?.client?.deliveryAddress,
                    email: val?.name?.email,
                };
                return data;
            });
            setShipment(temp);
        }
    }, [selectedRow]);

    

   
    const [showOutstanding, setShowOutstanding] = useState(false);

    const getBrands = async () => {
        setLoading(true);
        let res;
        if (role === "admin") {
            res = await handleGetRequest(`/allPackages`);
        } else if (role === "courier") {
            const log = localStorage.getItem("Zaziolog");
            if (log) {
                setShowOutstanding(true);
                
            }
            res = await handleGetRequest(`/courierpackages?userid=${userId}`);
        } else if (role === "client" || role === "reseller" || role === "corporate") {
            const log = localStorage.getItem("Zaziolog");
            if (log) {
                setShowOutstanding(true);
               
            }
            res = await handleGetRequest(`/clientpackages?userid=${userId}`);
        }

        setBrands(res?.data);
        setSearchedBrands(res?.data);
        setLoading(false);
    };
    useMemo(() => {
        if (role) {
            getBrands();
        }
    }, [skip, role]);

    useEffect(() => {
        const role = localStorage.getItem("role");
        const userId = localStorage.getItem("userId");
        setuserId(userId);
        setrole(role);
    }, []);

    const [del, setDel] = useState();
    const handelDel = async (dat) => {
        setDel(dat);
        setShowSoftStop(true);
    };

    const handles = async () => {
        if (del) {
            const data = {
                id: [del?.Waybill],
            };
            const res = await dispatch(handlePostRequest(data, "/deletePackage", true, true));
            console.log(res);
            if (res?.success) {
                setDel();
                getBrands();
                toast.success("packages deleted");
                window.location.reload();
            }
        }
    };

    const lbToKg = (pounds) => {
        const kilograms = pounds * 0.45359237;
        return kilograms;
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div style={{ display: "flex" }}>
                <Button icon="pi pi-ellipsis-v" className="p-button-rounded mr-2 Elipse_Icon" onClick={(e) => handleActionButton(e, rowData)} aria-controls="popup_menu" aria-haspopup />
                <Button
                    icon="pi pi-eye"
                    className="p-button-rounded mr-2 Elipse_Icon"
                    onClick={(e) => {
                        setPackageDetail(true);
                        setPackage(rowData);
                    }}
                    aria-controls="popup_menu"
                    aria-haspopup
                />
                <Button icon="pi pi-trash" iconPos="right" onClick={() => handelDel(rowData)} className="Btn__DarkDelete" style={{ width: "40px", backgroundColor: "red", border: "none" }} />
            </div>
        );
    };
    const handleActionButton = (e, rowdata) => {
        e.preventDefault();
        history.push(`package/${rowdata?.Waybill}`);
    };

    const onHideTyreBrandsDialog = () => {
        setShowDialog(false);
    };

    const onHideChangeDialog = () => {
        setShowChangeDialog(false);
    };

    const onHideFlightDialog = () => {
        setFlightDialog(false);
    };
    const handledDelete = async () => {
        if (selectedRow?.length > 0) {
            setShowSoftStop(true);
        } else {
            toast.error("Please select atleast one package");
        }
    };

    const [values, setValues] = useState({
        Waybill: "",
        name: "",
        status: "",
        flightNumber: "",
        merchant: "",
        date: "",
        consigneeName: "",
        trackingNumber: "",
        billTo: "",
        deliveryInfo: "",
        bill: "",
        weight: "",
        packageStatus: "",
        name: "",
    });

    const temporary = ["Waybill", "consigneeName", "status", "merchant", "flightNumber", "date", "trackingNumber", "billTo", "deliveryInfo", "bill", "weight", "packageStatus"];

    const handleApplyFilter = async (value, names) => {
        setLoading(true);
        if (names === "flightNumber") {
            setShipmentNumber(value);
        }
        const temp = values;
        // temporary.forEach((item) => {
        //     if (item !== names) {
        //         temp[item] = "";
        //     }
        // });
        // setValues(temp);
        temp[names] = value;
        setValues({ ...values, [names]: value });
        const result = await Axios.get(DEV + "/searchPackage", {
            params: {
                ...temp,
            },
        });
        setSearchedBrands(result?.data?.data);
        setLoading(false);
    };

    const handleFilter = (name) => {
        return <input style={{ width: "100%", height: "37px", borderRadius: "5px", border: "none" }} value={values[name]} onChange={(e) => handleApplyFilter(e.target.value, name)}></input>;
    };

    const onsuccess = () => {
        onHideTyreBrandsDialog();
        toast.success("package added.");
        window.location.reload();
    };

    const onsuccessChange = () => {
        onHideChangeDialog();
        toast.success("Status Changed.");
        window.location.reload();
    };

    const nameTemplate = (rowdata) => {
        return (
            <p>
                {rowdata?.name?.firstName} {rowdata?.name?.lastName}
            </p>
        );
    };
    const CnameTemplate = (rowdata) => {
        return <p>{rowdata?.courierName?.courier?.businessName}</p>;
    };
    const weightTemplate = (rowdata) => {
        return (
            <p>
                {rowdata?.weight.number} {rowdata?.weight.unit}
            </p>
        );
    };

    const shipmentTemplate = (rowdata) => {
        return <p>{rowdata?.shipmentMethod === "air" ? rowdata?.flightNumber : rowdata?.shipmentNumber}</p>;
    };

    const handleUpperCase = (name) => {
        return name.toUpperCase();
    };

    useEffect(() => {
        const dat = searchedBrands;
        const temp = dat.map((val) => {
            return {
                Waybill: handleUpperCase(val?.Waybill),
                CONSIGNEE: handleUpperCase(val?.consigneeName + " C/O ZAZIO JA LTD"),
                SHIPPER: handleUpperCase(val?.merchant),
                DESCRIPTION: handleUpperCase(val?.description),
                PCS: val?.numberOfPieces,
                WT: lbToKg(val?.weight?.number),
            };
        });
        setForXL(temp);
    }, [searchedBrands]);

    const onsuccessFlight = () => {
        onHideFlightDialog();
        toast.success("Flight Number Changed.");
        window.location.reload();
    };

    const onsuccessPackageDetail = () => {
        onHideFlightDialog();
        toast.success("Flight Number Changed.");
        window.location.reload();
    };

    const onHidePackageDetail = () => {
        setPackageDetail(false);
    };

    const [packageDetail, setPackageDetail] = useState(false);

    const [updates, setUpdates] = useState([]);
    const [wayBills, setWayBills] = useState([]);

    

    const handleStatusUpdate = () => {
        const clients = selectedRow.filter((val) => {
            if (val?.consigneeType === "client" || val?.consigneeType === "reseller" || val?.consigneeType === "corporate" || val?.consigneeType === "admin" || val?.consigneeType === "partner") {
                return val;
            }
        });
        const couriers = selectedRow.filter((val) => {
            if (val?.consigneeType === "courier") {
                return val;
            }
        });
        const groupedPackages = clients.reduce((groups, pkg) => {
            const { ...packageData } = pkg;

            const existingGroup = groups.find((group) => group?.name === pkg?.name?.firstName + " " + pkg?.name?.lastName);
            if (existingGroup) {
                existingGroup.description.push(packageData?.description);
            } else {
                groups.push({
                    id: pkg?.Waybill,
                    name: pkg?.name?.firstName + " " + pkg?.name?.lastName,
                    type: "client",
                    description: [packageData?.description],
                    email: pkg?.name?.email,
                    deliveryInfo: pkg?.deliveryInfo,
                    tracking: pkg?.trackingNumber,
                    shipmentMethod: pkg?.shipmentMethod,
                });
            }

            return groups;
        }, []);
        const cos = couriers.reduce((groups, pkg) => {
            const { ...packageData } = pkg;

            const existingGroup = groups.find((group) => group?.name === pkg?.courierName?.courier?.businessName);
            if (existingGroup) {
                existingGroup.description.push(packageData?.Waybill);
            } else {
                groups.push({
                    id: pkg?.Waybill,
                    name: pkg?.courierName?.courier?.businessName,
                    type: "courier",
                    description: [packageData?.Waybill],
                    email: pkg?.courierName?.email,
                    deliveryInfo: pkg?.deliveryInfo,
                });
            }

            return groups;
        }, []);
        const final = groupedPackages.concat(cos);
        const ids = selectedRow.map((val) => {
            return val?.Waybill;
        });
        setUpdates(final);
        setWayBills(ids);
        setShowChangeDialog(true);
    };

    const [uploadShow, setUploadShow] = useState(false);

    const onHideUpload = () => {
        setUploadShow(false);
    };

    const onSuccessUpload = () => {
        setUploadShow(false);
        toast.success("Invoice Uploaded");
        window.location.reload();
    };

    // for uploading the image
    const actionBody = (rowData) => {
        return (
            <div style={{ display: "flex" }}>
                <Button
                    icon="pi pi-upload"
                    className="p-button-rounded mr-2 Elipse_Icon"
                    onClick={(e) => {
                        setUploadShow(true);
                        setPackage(rowData);
                    }}
                    aria-controls="popup_menu"
                    aria-haspopup
                />
            </div>
        );
    };

    const handleApplyFilters = async (value, names) => {
        if (names === "flightNumber") {
            setShipmentNumber(value);
        }
        const temp = values;
        // temporary.forEach((item) => {
        //     if (item !== names) {
        //         temp[item] = "";
        //     }
        // });
        // setValues(temp);
        temp[names] = value;
        setValues({ ...values, [names]: value });
        if (names === "bill") {
            const result = brands.filter((val) => {
                const regex = new RegExp(value, "i");
                const name = val?.name?.firstName + " " + val?.name?.lastName;
                return regex.test(name);
            });
            setSearchedBrands(result);
        } else if (names === "weight") {
            const result = brands.filter((val) => {
                const weight = val?.weight?.number;
                return weight == value;
            });
            if (result.length === 0) {
                setSearchedBrands(brands);
            } else {
                setSearchedBrands(result);
            }
        } else {
            setSearchedBrands(brands);
        }
    };

    const handleClientSearch = (name) => {
        return <input style={{ width: "100%", height: "37px", borderRadius: "5px", border: "none" }} value={values[name]} onChange={(e) => handleApplyFilters(e.target.value, name)}></input>;
    };

    const [showSoftStop, setShowSoftStop] = useState(false);

    const onHideSoftStop = () => {
        setShowSoftStop(false);
    };

    const onSuccessSoftStop = async () => {
        setShowSoftStop(false);
        handles();
        if (selectedRow?.length > 0) {
            const selectedIds = selectedRow.map((val) => {
                return val?.Waybill;
            });
            const data = {
                id: selectedIds,
            };
            const res = await dispatch(handlePostRequest(data, "/deletePackage", true, true));
            if (res?.success) {
                getBrands();
                toast.success("packages deleted");
                window.location.reload();
            }
        }
    };

    const rowClass = (data) => {
        return {
            "row-notes": data.notes,
        };
    };

    // const stockBodyTemplate = (rowData) => {
    //     const stockClassName = classNames({
    //         'outofstock': rowData.quantity === 0,
    //         'lowstock': rowData.quantity > 0 && rowData.quantity < 10,
    //         'instock': rowData.quantity > 10
    //     });

    const handleGenerateInvoice = async () => {
        const status = "Preparing Package for Shipment";
        const selectedIds = selectedRow.map((val) => {
            return val?.Waybill;
        });
        const dat = {
            status: status,
            package: updates,
            ids: selectedIds,
        };
        const res = await handlePutRequest(dat, "/packageInvoice");
        if (res?.success) {
            toast.success("invoices generated successfully");
        }
    };

 

    const onHideOutstanding = () => {
        setShowOutstanding(false);
        localStorage.removeItem("Zaziolog");
    };

    const onSuccessOutstanding = () => {
        setShowOutstanding(false);
        localStorage.removeItem("Zaziolog");
    };

    return (
        <>
            <Dialog visible={showOutstanding} style={{ width: "650px" }} onHide={() => onHideOutstanding()}>
                <Outstanding onHideOutStanding={onHideOutstanding} onSuccessOutstanding={onSuccessOutstanding} />
            </Dialog>
            <Dialog visible={showDialog} header="Packages" style={{ width: "650px" }} onHide={() => setShowDialog(false)}>
                <AddPackageDialog onHideTyreBrandsDialog={onHideTyreBrandsDialog} onsuccess={onsuccess} />
            </Dialog>

            <Dialog visible={showChangeDialog} header="Package Status Change" style={{ width: "650px" }} onHide={() => setShowChangeDialog(false)}>
                <ChangeStatusDialog onHideChangeDialog={onHideChangeDialog} onsuccessChange={onsuccessChange} data={updates} id={wayBills} />
            </Dialog>

            <Dialog visible={FlightDialog} header="Bulk Flight Number Change" style={{ width: "650px" }} onHide={() => setFlightDialog(false)}>
                <BulkFlight onHideChangeDialog={onHideFlightDialog} onsuccessChange={onsuccessFlight} data={selectedRow}></BulkFlight>
            </Dialog>

            <Dialog visible={packageDetail} header="Package Detail" style={{ width: "650px" }} onHide={() => setPackageDetail(false)}>
                <PackageDetail onHideChangeDialog={onHidePackageDetail} onsuccessChange={onsuccessPackageDetail} data={pack}></PackageDetail>
            </Dialog>

            <Dialog visible={uploadShow} header="Upload Invoice" style={{ width: "650px" }} onHide={() => setUploadShow(false)}>
                <UploadImageDialog onHideUpload={onHideUpload} onSuccessUpload={onSuccessUpload} pack={pack}></UploadImageDialog>
            </Dialog>

            <Dialog visible={showSoftStop} style={{ width: "650px" }} onHide={() => setShowSoftStop(false)}>
                <SofStop onHideSoftStop={onHideSoftStop} onSuccessSoftStop={onSuccessSoftStop} type="package"></SofStop>
            </Dialog>

            <div className="Page__Header">
                <div>
                    <h2>Packages</h2>
                </div>
                {role == "admin" && (
                    <div className="Top__Btn">
                        <Tooltip
                            // options
                            title="Generate Invoices of selected packages"
                            position="bottom"
                            trigger="mouseenter"
                            arrow={true}
                        >
                            {selectedRow.length > 0 && <Button icon="pi pi-wallet" iconPos="right" onClick={() => handleGenerateInvoice()} className="Btn__Blue" />}
                        </Tooltip>
                        <Tooltip
                            // options
                            title="Download packages in excel"
                            position="bottom"
                            trigger="mouseenter"
                            arrow={true}
                        >
                            <JsonToExcel title=" " class="" data={forXL} fileName={`Flight ${values?.flightNumber}`} btnClassName="buttonsaaa pi pi-download" />
                        </Tooltip>
                        <Tooltip
                            // options
                            title="Change status of selected packages"
                            position="bottom"
                            trigger="mouseenter"
                            arrow={true}
                        >
                            {selectedRow.length > 0 && <Button icon="pi pi-file-o" iconPos="right" onClick={() => handleStatusUpdate()} className="Btn__Blue" />}
                        </Tooltip>
                        <Tooltip
                            // options
                            title="Change Flight Number of selected packages"
                            position="bottom"
                            trigger="mouseenter"
                            arrow={true}
                        >
                            {selectedRow.length > 0 && <Button icon="pi pi-send" iconPos="right" onClick={() => setFlightDialog(true)} className="Btn__Blue" />}
                        </Tooltip>
                        <Tooltip
                            // options
                            title="Add new Package"
                            position="bottom"
                            trigger="mouseenter"
                            arrow={true}
                        >
                            <Button icon="pi pi-plus" iconPos="right" onClick={() => setShowDialog(true)} className="Btn__Blue" />
                        </Tooltip>
                        <Tooltip
                            // options
                            title="Delete Selected Packages"
                            position="bottom"
                            trigger="mouseenter"
                            arrow={true}
                        >
                            <Button icon="pi pi-trash" iconPos="right" onClick={handledDelete} className="Btn__DarkDelete" />
                        </Tooltip>
                    </div>
                )}
            </div>

            <div className="card">
                <DataTable
                    filterDisplay="row"
                    paginator
                    rows={100}
                    rowsPerPageOptions={[100, 500, 1000]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                    emptyMessage="No List found."
                    responsiveLayout="scroll"
                    value={searchedBrands}
                    selection={selectedRow}
                    onSelectionChange={(e) => setselectedRow(e.value)}
                    sortMode="multiple"
                    rowClassName={rowClass}
                    loadingIcon="pi pi-spinner"
                    loading={loading}
                >
                    <Column selectionMode="multiple" style={{ width: "3em" }} />
                    {role === "admin" && <Column header="Action" body={actionBodyTemplate} />}
                    <Column filter field="Waybill" header="Way Bill" filterElement={() => handleFilter("Waybill")} sortable sortField="Waybill" />
                    <Column filter field="billTo" header="Package Name" filterElement={() => handleFilter("billTo")} sortable />
                    {role !== "client" && <Column filter body={nameTemplate} header="Bill To" filterElement={() => handleFilter("name")} />}
                    {role === "admin" && <Column filter field="consigneeName" header="Consignee Name" filterElement={() => handleFilter("consigneeName")} sortable />}
                    <Column filter body={shipmentTemplate} header="Shipment Number" filterElement={() => handleFilter("flightNumber")} sortable sortField="flightNumber" />
                    {role !== "client" && <Column filter body={CnameTemplate} header="Courier Name" filterElement={() => handleFilter("courierName")} />}

                    {role === "admin" && <Column filter field="merchant" header="Merchant" filterElement={() => handleFilter("merchant")} />}
                    <Column filter field="trackingNumber" header="Tracking Number" filterElement={() => handleFilter("trackingNumber")} />
                    <Column filter field="deliveryInfo" header="Delivery Information" filterElement={() => handleFilter("deliveryInfo")} />
                    <Column filter field="packageStatus" header="Package Status" filterElement={() => handleFilter("packageStatus")} />
                    <Column filter body={weightTemplate} header="Weight of package" filterElement={() => handleClientSearch("weight")} />

                    <Column filter header="Created On" field="date" filterElement={() => handleFilter("date")} />

                    {role !== "admin" && <Column header="Action" body={actionBody} />}
                </DataTable>
            </div>
        </>
    );
}

export default Packages;
