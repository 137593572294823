import React, { useEffect, useMemo, useRef, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../services/GetTemplate";
import moment from "moment";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import { toast } from "react-toastify";
import Axios from "axios";
import { DEV } from "../../services/constants";
import AddPackageDialog from "./AddLabelDialog";
import { Dialog } from "primereact/dialog";
import SofStop from "../../modals/SofStop";
import { Tooltip } from "react-tippy";

function Labels() {
    const [selectedRow, setselectedRow] = useState([]);
    const [showDialog, setShowDialog] = useState(false);
    const [brands, setBrands] = useState([]);
    const [skip, setSkip] = useState(0);
    const [labels, setLabels] = useState([]);
    const [searchedBrands, setSearchedBrands] = useState([]);
    const [loading, setLoading] = useState(true);

    const dispatch = useDispatch();

    const menu = useRef(null);
    const breadItems = [{ label: "Home", url: "/" }, { label: "Labels" }];

    const home = { icon: "pi pi-home", url: "/" };
    const history = useHistory();


    const getBrands = async () => {
        setLoading(true);
        const res = await handleGetRequest("/allLabels");
        setBrands(res?.data);
        setSearchedBrands(res?.data);
        setLoading(false);
    };
    useMemo(() => {
        getBrands();
    }, [skip]);

    console.log(brands);

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-ellipsis-v" className="p-button-rounded mr-2 Elipse_Icon" onClick={(e) => handleActionButton(e, rowData)} aria-controls="popup_menu" aria-haspopup />
            </div>
        );
    };
    const handleActionButton = (e, rowdata) => {
        e.preventDefault();
        history.push(`label/${rowdata?._id}`);
    };

    const onHideTyreBrandsDialog = () => {
        setShowDialog(false);
    };
    const handledDelete = async () => {
        setShowSoftStop(true);
    };

    const [values, setValues] = useState({
        inventoryNumber: "",
        name: "",
        status: "",
        flightNumber: "",
        merchant: "",
        date: "",
        consigneeName: "",
        flightNumber: "",
        weight: "",
        deliveryInfo: "",
    });

    const temporary = ["trackingNumber", "shipmentMethod", "date", "consigneeName", "flightNumber", "weight", "deliveryInfo"];

    const handleApplyFilter = async (value, names) => {
        setLoading(true);
        const temp = values;
        // temporary.forEach((item) => {
        //     if (item !== names) {
        //         temp[item] = "";
        //     }
        // });
        // setValues(temp);
        temp[names] = value;
        setValues({ ...values, [names]: value });
        const result = await Axios.get(DEV + "/searchLabel", {
            params: {
                ...temp,
            },
        });
        setSearchedBrands(result?.data?.data);
        setLoading(false);
    };

    const handleFilter = (name) => {
        return <input style={{ width: "100%", height: "37px", borderRadius: "5px", border: "none" }} value={values[name]} onChange={(e) => handleApplyFilter(e.target.value, name)}></input>;
    };

    const onsuccess = () => {
        onHideTyreBrandsDialog();
        toast.success("Label added.");
        window.location.reload();
    };

    const nameTemplate = (rowdata) => {
        return (
            <p>
                {rowdata?.consigneeName?.firstName} {rowdata?.consigneeName?.lastName}
            </p>
        );
    };
    const weightTemplate = (rowdata) => {
        return <p>{rowdata?.weight} Lb</p>;
    };

    useEffect(() => {
        if (selectedRow.length > 0) {
            const data = selectedRow?.map((val) => {
                return {
                    shipmentMethod: val?.shipmentMethod,
                    deliveryAddress: val?.deliveryAddress,
                    consigneeName: val?.consigneeName,
                    trackingNumber: val?.trackingNumber,
                    courierName: val?.courierName ? val?.courierName : "",
                    description: val?.description,
                    flightNumber: val?.flightNumber,
                    shipmentNumber: val?.shipmentNumber,
                    weight: val?.weight,
                    courier: val?.courierName,
                    deliveryInfo: val?.deliveryInfo,
                };
            });
            setLabels(data);
        }
    }, [selectedRow]);

    const generatePDF = () => {
        localStorage.setItem("labels", JSON.stringify(labels));
        history.push("/BulkLabels");
    };

    const handleApplyFilters = async (value, names) => {
        const temp = values;
        temporary.forEach((item) => {
            if (item !== names) {
                temp[item] = "";
            }
        });
        setValues(temp);
        setValues({ ...values, [names]: value });
        if (names === "weight") {
            const result = brands.filter((val) => {
                const weight = val?.weight;
                return parseInt(weight) == parseInt(value);
            });
            if (result.length === 0) {
                setSearchedBrands(brands);
            } else {
                setSearchedBrands(result);
            }
        } else {
            setSearchedBrands(brands);
        }
    };

    const handleClientSearch = (name) => {
        return <input style={{ width: "100%", height: "37px", borderRadius: "5px", border: "none" }} value={values[name]} onChange={(e) => handleApplyFilters(e.target.value, name)}></input>;
    };

    const [showSoftStop, setShowSoftStop] = useState(false);

    const onHideSoftStop = () => {
        setShowSoftStop(false);
    };

    const onSuccessSoftStop = async () => {
        setShowSoftStop(false);
        if (selectedRow?.length > 0) {
            const selectedIds = selectedRow.map((val) => {
                return val?._id;
            });
            const data = {
                id: selectedIds,
            };
            const res = await dispatch(handlePostRequest(data, "/deleteLabel", true, true));
            if (res?.success) {
                getBrands();
                toast.success("label deleted");
                window.location.reload();
            }
        }
    };

    const shipmentTemplate = (rowdata) => {
        return <p>{rowdata?.flightNumber ? rowdata?.flightNumber : rowdata?.shipmentNumber}</p>;
    };

    return (
        <>
            <Dialog visible={showDialog} header="Labels" style={{ width: "650px" }} onHide={() => setShowDialog(false)}>
                <AddPackageDialog onHideTyreBrandsDialog={onHideTyreBrandsDialog} onsuccess={onsuccess} />
            </Dialog>

            <Dialog visible={showSoftStop} style={{ width: "650px" }} onHide={() => setShowSoftStop(false)}>
                <SofStop onHideSoftStop={onHideSoftStop} onSuccessSoftStop={onSuccessSoftStop} type="Label"></SofStop>
            </Dialog>

            <div className="Page__Header">
                <div>
                    <h2>Labels</h2>
                </div>
                <div className="Top__Btn">
                    <Tooltip
                        // options
                        title="Download selected labels"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        {selectedRow?.length > 0 && <Button icon="pi pi-download" onClick={generatePDF} iconPos="right" className="Btn__Blue" />}
                    </Tooltip>
                    <Tooltip
                        // options
                        title="Add new label"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-plus" iconPos="right" onClick={() => setShowDialog(true)} className="Btn__Blue" />
                    </Tooltip>
                    <Tooltip
                        // options
                        title="Delete selected labels"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi-trash" iconPos="right" onClick={handledDelete} className="Btn__DarkDelete" style={{ width: "240px" }} />
                    </Tooltip>
                </div>
            </div>

            <div className="card">
                <DataTable
                    filterDisplay="row"
                    paginator
                    rows={100}
                    rowsPerPageOptions={[100, 500,1000]}
                    className="datatable-responsive"
                    paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                    currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                    emptyMessage="No List found."
                    responsiveLayout="scroll"
                    value={searchedBrands}
                    selection={selectedRow}
                    onSelectionChange={(e) => setselectedRow(e.value)}
                    sortMode="multiple"
                    loadingIcon="pi pi-spinner"
                    loading={loading}
                >
                    <Column selectionMode="multiple" style={{ width: "3em" }} />
                    <Column header="Action" body={actionBodyTemplate} />
                    <Column filter field="trackingNumber" header="Inventory Number" filterElement={() => handleFilter("trackingNumber")} sortable />
                    <Column filter field="consigneeName" header="Consignee Name" filterElement={() => handleFilter("consigneeName")} sortable />
                    <Column filter field="shipmentMethod" header="Shipment Method" filterElement={() => handleFilter("shipmentMethod")} sortable />
                    <Column filter body={shipmentTemplate} header="Shipment Number" filterElement={() => handleFilter("flightNumber")} sortable />
                    <Column filter field="deliveryInfo" header="Delivery Info" filterElement={() => handleFilter("deliveryInfo")} sortable />
                    <Column filter body={weightTemplate} header="Weight of package" filterElement={() => handleClientSearch("weight")} />
                    
                    <Column filter header="Created On" field="date" filterElement={() => handleFilter("date")} />
                    
                </DataTable>
            </div>
        </>
    );
}

export default Labels;
