import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import classNames from "classnames";
import { Button } from "primereact/button";
import { handleGetRequest } from "../../services/GetTemplate";
import { useDispatch } from "react-redux";
import { handlePostRequest } from "../../services/PostTemplate";
import Select from "react-select";
import moment from "moment";
import { useHistory, useParams } from "react-router-dom";
import { InputText } from "primereact/inputtext";

const AddLabelDialog = ({ onHideTyreBrandDialog, onsuccess }) => {
    const [packages, setPackages] = useState([]);
    const [pack, setPack] = useState([]);
    const dispatch = useDispatch();
    const [selectedPackage, setSelectedPackage] = useState();
    const [fetchedPackage, setFetchedPackage] = useState();
    const [selectedCourier, setSelectedCourier] = useState();
    const [type, setType] = useState(null);
    const [FNo, setFNo] = useState(null);
    const [filteredPackages, setFilteredPackages] = useState([]);

    const history = useHistory();


    const formik = useFormik({
        initialValues: {
            barcode: "",
            consigneeName: "",
            description: "",
            weight: "",
            flightNumber: "",
            courierName: "",
            shipmentNumber: "",
            numberOfPieces: "",
            trackingNumber: "",
            unit: "",
            shipmentMethod: "",
            deliveryAddress: "",
            date: moment().format("DD-MM-YYYY"),
            deliveryInfo: "",
        },

        onSubmit: async (data) => {
            if (type === "flight") {
                const dat = filteredPackages;
                const result = await dispatch(handlePostRequest(dat, "/AddMassLabel", true, true));
                if (result !== "error") {
                    onsuccess();
                }
            } else if (type === "package") {
                const user = await handleGetRequest("/getuser/" + fetchedPackage?.name?._id);
                data.barcode = fetchedPackage?._id;
                data.consigneeName = fetchedPackage?.consigneeName;
                data.description = fetchedPackage?.description;
                data.weight = fetchedPackage?.weight?.number;
                if (fetchedPackage?.shipmentMethod === "air") {
                    data.flightNumber = fetchedPackage?.flightNumber;
                }
                data.courierName = fetchedPackage?.courierName?.courier?.businessName;
                data.unit = fetchedPackage?.weight?.unit;
                if (fetchedPackage?.shipmentMethod === "shipment") {
                    data.shipmentNumber = fetchedPackage?.shipmentNumber;
                }
                data.numberOfPieces = fetchedPackage?.numberOfPieces;
                data.trackingNumber = fetchedPackage?.Waybill;
                data.shipmentMethod = fetchedPackage?.shipmentMethod;
                if (user?.data?.role === "client") {
                    data.deliveryAddress = user?.data?.client?.deliveryAddress;
                } else {
                    data.deliveryAddress = user?.data?.courier?.deliveryAddress;
                }
                data.deliveryInfo = fetchedPackage?.deliveryInfo;
                const result = await dispatch(handlePostRequest(data, "/addLabel", true, true));
                if (result !== "error") {
                    onsuccess();
                }
            }
            console.log(data);
        },
    });
    const isFormFieldValid = (name) => !!(formik.touched[name] && formik.errors[name]);
    const getFormErrorMessage = (name) => {
        return isFormFieldValid(name) && <small className="p-error">{formik.errors[name]}</small>;
    };

    const getData = async () => {
        const res = await handleGetRequest("/allPackages");
        setPack(res?.data);
        const pack = res?.data;
        const temp = pack?.map((item) => {
            return {
                label: item?.Waybill,
                value: item?._id,
            };
        });
        setPackages(temp);
    };

    useEffect(() => {
        getData();
    }, []);

    const [count, setCount] = useState(0);

    useEffect(() => {
        if (FNo) {
            const teme = [];
            const temp = pack.filter((item) => {
                if (item?.flightNumber === FNo) {
                    teme.push({
                        barcode: item._id,
                        consigneeName: item?.consigneeName,
                        description: item?.description,
                        weight: item?.weight?.number,
                        flightNumber: item?.flightNumber,
                        courierName: item?.courierName?.courier?.businessName,
                        unit: item?.weight?.unit,
                        shipmentNumber: item?.shipmentNumber,
                        trackingNumber: item?.Waybill,
                        shipmentMethod: item?.shipmentMethod,
                        deliveryAddress: item?.name?.client?.deliveryAddress,
                        date: moment().format("DD-MM-YYYY"),
                        deliveryInfo: item?.deliveryInfo,
                    });
                }
            });
            
            setFilteredPackages(teme);
        }
    }, [FNo]);

    console.log(filteredPackages);
    

    const handleSelectedPackage = (id) => {
        const temp = pack.filter((item) => item._id === id);
        setFetchedPackage(temp[0]);
    };

    useEffect(() => {
        if (selectedPackage) {
            handleSelectedPackage(selectedPackage?.value);
        }
    }, [selectedPackage]);
    console.log(fetchedPackage);

    return (
        <>
            <form onSubmit={formik.handleSubmit} className="p-fluid p-mt-2">
                <div className="p-fluid p-formgrid grid mb-2">
                    <div className="p-field col-12 md:col-12">
                        <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                            <label htmlFor="consigneeName" className={classNames({ "p-error": isFormFieldValid("consigneeName") }, "Label__Text")}>
                                Label Type
                            </label>
                            <select value={type} onChange={(e) => setType(e.target.value)} style={{ height: "35px", borderRadius: "6px", border: "1px solid #cecece" }}>
                                <option selected disabled>
                                    Select Type
                                </option>
                                <option value="package">By Package</option>
                                <option value="flight">By Flight</option>
                            </select>

                            {getFormErrorMessage("consigneeName")}
                        </div>
                    </div>
                </div>
                {type === "flight" && (
                    <div className="p-fluid p-formgrid grid mb-2">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                                <label htmlFor="consigneeName" className={classNames({ "p-error": isFormFieldValid("consigneeName") }, "Label__Text")}>
                                    Flight Number
                                </label>
                                <InputText id="Waybill" name="Waybill" value={FNo} onChange={(e) => setFNo(e.target.value)} className={classNames({ "p-invalid": isFormFieldValid("Waybill") }, "Input__Round")} />

                                {getFormErrorMessage("consigneeName")}
                            </div>
                        </div>
                    </div>
                )}
                {type === "package" && (
                    <div className="p-fluid p-formgrid grid mb-2">
                        <div className="p-field col-12 md:col-12">
                            <div className="p-field" style={{ display: "flex", flexDirection: "column" }}>
                                <label htmlFor="consigneeName" className={classNames({ "p-error": isFormFieldValid("consigneeName") }, "Label__Text")}>
                                    Package Number
                                </label>
                                <Select options={packages} onChange={setSelectedPackage} value={selectedPackage}></Select>

                                {getFormErrorMessage("consigneeName")}
                            </div>
                        </div>
                    </div>
                )}
                {filteredPackages?.length > 0 && (
                    <div>
                        <p>{filteredPackages?.length} Labels</p>
                    </div>
                )}
                <div className="Down__Btn">
                    <Button label="Generate Label" className="Btn__Dark" />
                </div>
            </form>
        </>
    );
};

export default AddLabelDialog;
