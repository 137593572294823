import React, { useEffect, useMemo, useState } from "react";
import { BreadCrumb } from "primereact/breadcrumb";
import { Button } from "primereact/button";
import { useHistory, useParams } from "react-router-dom";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { handleGetRequest } from "../../services/GetTemplate";
import Axios from "axios";
import { DEV } from "../../services/constants";
import "./deliveries.scss";
import { Tooltip } from "react-tippy";
import { toast } from "react-toastify";

function BillingReports() {
    const [selectedRow, setselectedRow] = useState([]);
    const [brands, setBrands] = useState([]);
    const [skip, setSkip] = useState(0);
    const [userId, setuserId] = useState("");

    useEffect(() => {
        const userId = localStorage.getItem("userId");
        setuserId(userId);
    }, []);

    const breadItems = [{ label: "Home", url: "/" }, { label: "Invoices" }];

    const home = { icon: "pi pi-home", url: "/" };
    const history = useHistory();

    const getBrands = async () => {
        if (userId) {
            const res = await handleGetRequest(`/clientInvoices/${userId}`);
            setBrands(res?.data);
        }
    };
    useMemo(() => {
        getBrands();
    }, [skip, userId]);

    const actionBodyTemplate = (rowData) => {
        return (
            <div>
                <Button icon="pi pi-ellipsis-v" className="p-button-rounded mr-2 Elipse_Icon" onClick={(e) => handleActionButton(e, rowData)} aria-controls="popup_menu" aria-haspopup />
            </div>
        );
    };
    const handleActionButton = (e, rowdata) => {
        e.preventDefault();
        history.push(`/userInvoice?id=${rowdata._id}`);
    };

    const [values, setValues] = useState({
        invoiceNo: "",
        clientName: "",
        customerType: "",
        weight: "",
        invoiceAmount: "",
        shipmentNumber: "",
        reviewStatus: "",
        paymentStatus: "",
        date: "",
    });

    const temporary = ["invoiceNo", "clientName", "customerType", "weight", "invoiceAmount", "shipmentNumber", "reviewStatus", "paymentStatus", "date"];

    const handleApplyFilter = async (value, names) => {
        const temp = values;
        temporary.forEach((item) => {
            if (item !== names) {
                temp[item] = "";
            }
        });
        setValues(temp);
        setValues({ ...values, [names]: value });
        const result = await Axios.get(DEV + "/searchInvoice", {
            params: {
                [names]: value,
            },
        });
        setBrands(result?.data?.data);
    };

    const handleFilter = (name) => {
        return <input style={{ width: "100%", height: "37px", borderRadius: "5px", border: "none" }} value={values[name]} onChange={(e) => handleApplyFilter(e.target.value, name)}></input>;
    };

    const rowClass = (data) => {
        return {
            "row-status": data.paymentStatus === "paid",
        };
    };

    const handleSelected = () => {
        if (selectedRow?.length > 0) {
            let tata = false;
            selectedRow.forEach((item) => {
                if (item.paymentStatus === "paid") {
                    tata = true;
                }
            });
            if (tata) {
                toast.error("You can't select paid invoices");
            } else {
                const temp = selectedRow;
                if (temp?.length === 1) {
                    history.push(`/userInvoice?id=${selectedRow?.[0]._id}`);
                } else {
                    const aggregated = {
                        MonthlyFee: 0,
                        TotalAmount: 0,
                        Waybills: "",
                        WebsiteFee: 0,
                        dangerousItemFee: 0,
                        deliveryFee: 0,
                        customDuties: 0,
                        declarationFee: 0,
                        discountedAmount: 0,
                        loyaltyDiscount: 0,
                        oversizeFee: 0,
                        packagingFee: 0,
                        pickupFee: 0,
                        splitFee: 0,
                        weight: 0,
                        packages: [],
                        clientId: selectedRow[0].clientId,
                        clientName: selectedRow[0].clientName,
                        email: selectedRow[0].email,
                        phone: selectedRow[0].phone,
                        customerType: selectedRow[0].customerType,
                        date: selectedRow[0].date,
                        descriptions: "",
                        shipmentNumber: "",
                        invoiceAmount: 0,
                        paidAmount: 0,
                        paymentStatus: selectedRow[0].paymentStatus,
                        itemsPrice: 0,
                        // Add other necessary fields
                    };
                    const tosa = selectedRow.forEach((item) => {
                        aggregated.MonthlyFee += item.MonthlyFee;
                        aggregated.TotalAmount += item.TotalAmount;
                        aggregated.WebsiteFee += item.WebsiteFee;
                        aggregated.dangerousItemFee += item.dangerousItemFee ?? 0;
                        aggregated.deliveryFee += item.deliveryFee;
                        aggregated.customDuties += item.customDuties;
                        aggregated.declarationFee += item.declarationFee;
                        aggregated.discountedAmount += item.discountedAmount;
                        aggregated.loyaltyDiscount += item.loyaltyDiscount;
                        aggregated.oversizeFee += item.oversizeFee;
                        aggregated.packagingFee += item.packagingFee;
                        aggregated.pickupFee += item.pickupFee;
                        aggregated.splitFee += item.splitFee;
                        aggregated.weight += item.weight;
                        aggregated.packages = aggregated.packages.concat(item.packages);
                        aggregated.descriptions += aggregated.descriptions ? `,${item.descriptions}` : item.descriptions;
                        aggregated.shipmentNumber += aggregated.shipmentNumber ? `,${item.shipmentNumber}` : item.shipmentNumber;
                        aggregated.Waybills += aggregated.Waybills ? `,${item.Waybills}` : item.Waybills;
                        aggregated.invoiceAmount += item.invoiceAmount;
                        aggregated.paidAmount += item.paidAmount??0;
                        aggregated.itemsPrice += item.itemsPrice??0;
                    });
                    const sosa = selectedRow.map((item) => {
                        return {
                            _id: item._id,
                            paidAmount: item.invoiceAmount,
                        };
                    });
                    localStorage.setItem("Zazioaggregated", JSON.stringify(aggregated));
                    localStorage.setItem("Zaziopaid", JSON.stringify(sosa));
                    localStorage.setItem("PaymentUser", "user");
                    history.push("/bulkInvoicesUser");
                }
            }
        } else {
            toast.error("Please select atleast one invoice to pay");
        }
    };

    return (
        <>
            <div className="Page__Header">
                <div>
                    <h2>Invoices</h2>
                    <BreadCrumb model={breadItems} home={home} />
                </div>
                <div className="Top__Btn">
                    <Tooltip
                        // options
                        title="Pay Selected Invoices"
                        position="bottom"
                        trigger="mouseenter"
                        arrow={true}
                    >
                        <Button icon="pi pi pi-wallet" iconPos="right" onClick={handleSelected} className="Btn__Blue" />
                    </Tooltip>
                </div>
            </div>
            <div className="grid">
                <div className="col-12">
                    <div className="card">
                        <DataTable
                            filterDisplay="row"
                            paginator
                            rows={100}
                            rowsPerPageOptions={[100, 250, 500]}
                            className="datatable-responsive"
                            paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} Records"
                            emptyMessage="No List found."
                            responsiveLayout="scroll"
                            value={brands}
                            selection={selectedRow}
                            onSelectionChange={(e) => setselectedRow(e.value)}
                            rowClassName={rowClass}
                        >
                            <Column selectionMode="multiple" style={{ width: "3em" }} />
                            <Column header="Action" body={actionBodyTemplate} />
                            <Column filter field="invoiceNo" header="ID" filterElement={() => handleFilter("invoiceNo")} />
                            <Column filter field="clientName" header="Client Name" filterElement={() => handleFilter("clientName")} />
                            <Column filter header="Total Weight" field="weight" filterElement={() => handleFilter("weight")} />
                            <Column filter header="Remaining Amount" field="invoiceAmount" filterElement={() => handleFilter("invoiceAmount")} />
                            <Column filter header="Paid Amount" field="paidAmount" filterElement={() => handleFilter("paidAmount")} />
                            <Column filter header="Contents" field="descriptions" filterElement={() => handleFilter("descriptions")} />
                            <Column filter header="Payment Status" field="paymentStatus" filterElement={() => handleFilter("paymentStatus")} />
                            <Column filter header="Issue Date" field="date" filterElement={() => handleFilter("date")} />
                        </DataTable>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BillingReports;
